import { assert } from '~/utils/assertion'
import { queryClient } from './query-client'
import EntityApi from '~/api/entity-api'
import { EntityName } from '~/constants'


// TODO: ввести в использование
// в дата провайдере
// в компонентах show/edit/create
// учесть, что он асинхронный, и проверить, что это не вызывает проблем с отрисовкой форм
export const parseResource = async (resource: string) => {
  const [applicationId, entityName] = resource.split(':')
  assert(applicationId, 'No applicationId in data provider')
  assert(entityName, 'No entityName in data provider')

  const EntityName = applicationId === '3' ? 'content-entity-templates' : 'entity-templates'

  const templates = await queryClient.ensureQueryData({
    queryKey: [EntityName, applicationId],
    queryFn: ({ queryKey }) =>
      EntityApi.getTemplates(Number(queryKey[1])).then(res => res.data),
  })

  const template = templates.find(t => t.symbolCode === entityName)

  assert(template, `Template "${entityName}" not found`)
  const cachedData = queryClient.getQueryCache().getAll()

  return {
    applicationId: Number(applicationId),
    templates,
    entityName: entityName as EntityName,
    template,
  }
}
