import {
  ArrayField, ChipField, Create,
  Datagrid, DeleteButton, Edit, EditButton,
  FilterButton,
  FilterForm, Labeled,
  List,
  NumberInput,
  Pagination, ReferenceArrayField, ReferenceArrayInput, ReferenceField, ReferenceInput, SelectArrayInput,
  SelectColumnsButton, SelectInput, SimpleForm, TextField,
  TextInput
} from "react-admin"
import React from "react"
import { Stack } from "@mui/material"

export const RegionsList = () => {
  return (
    <List pagination={<PostPagination />}>
      <ListToolbar />
      <Datagrid rowClick="edit">
        <TextField label="Id" source="id" />
        <TextField label="Название" source="name" />
        <TextField label="Символьный код" source="symbolCode" />
        <TextField label="Валюта" source="currency.name" />
        <ArrayField label="Приложения" source="applications">
          <Datagrid bulkActionButtons={false}>
            <ChipField source="name" />
          </Datagrid>
        </ArrayField>
        <EditButton />
        <DeleteButton />
      </Datagrid>
    </List>
  )
}

export const RegionsEdit = () => {
  return (
    <Edit>
      <SimpleForm>
        <Labeled label={"Id"}>
          <TextInput source="id" readOnly />
        </Labeled>
        <Labeled label={"Название"}>
          <TextInput source="name" />
        </Labeled>
        <Labeled label={"Символьный код"}>
          <TextInput source="symbolCode" />
        </Labeled>
        <Labeled label={"Id валюты"}>
          <TextInput source="currencyId" />
        </Labeled>
        <Labeled label={"Список приложений"}>
          <ReferenceArrayInput
            source="applications"
            reference="applications"
          >
            <SelectArrayInput
              label="Выберите приложения"
              source="applicationIds"
              optionValue="id"
              optionText="name"
            />
          </ReferenceArrayInput>
        </Labeled>
      </SimpleForm>
    </Edit>
  )
}

export const RegionsCreate = () => {
  return (
    <Create>
      <SimpleForm>
        <Labeled label="Название">
          <TextInput source="name" />
        </Labeled>
        <Labeled label="Символьный код">
          <TextInput source="symbolCode" />
        </Labeled>
        <Labeled label="Список приложений">
          <ReferenceArrayInput source="applicationIds" reference="applications">
            <SelectArrayInput
              label="приложения"
              source="applications"
              optionValue="id"
              optionText="name"
            />
          </ReferenceArrayInput>
        </Labeled>
      </SimpleForm>
    </Create>
  )
}

const ListToolbar = () => (
  <Stack
    direction="row"
    justifyContent="flex-start"
    alignItems="center"
    spacing={0}
    sx={{ ml: 2, mr: 2 }}
  >
    <FilterForm filters={[
      <TextInput label="Поиск" source="query" />,
      <TextInput label="Id" source="id" />,
      <TextInput label="Название" source="name" />,
      <TextInput label="Символьный код" source="symbolCode" />,
      <NumberInput label="Id валюты" source="currencyId" />
    ]} />
    <div>
      <SelectColumnsButton />
      <FilterButton filters={[
        <TextInput label="Поиск" source="query" />,
        <TextInput label="Id" source="id" />,
        <TextInput label="Название" source="name" />,
        <TextInput label="Символьный код" source="symbolCode" />,
        <NumberInput label="Id валюты" source="currencyId" />
      ]} />
    </div>
  </Stack>
)

const PostPagination = () => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} />
)