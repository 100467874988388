import { DataProvider } from "react-admin"
import { Response, ResponseObject } from "~/utils/types"
import { parserParamsForGetList } from "~/utils/dataProviderParams"
import Api from "~/api/wrapper-api"

export const regionsDataProvider: DataProvider = {
  async getList(resource: string, params): Promise<Response> {
    const filter = parserParamsForGetList(params, resource)
    const res = await Api.getList(resource, { ...filter })
    return { data: res.data.rows, total: res.data.count }
  },
  async getOne(resource: string, { id }: { id: number }): Promise<ResponseObject> {
    const res = await Api.get(resource, id)
    const { applications, ...data } = res.data
    const apps = applications.map(application => application.id)
    console.log('region.getOne', { applications: apps, ...data })
    return { data: { applications: apps, ...data } }
  },
  async create(resource: string, params): Promise<ResponseObject> {
    const res = await Api.create(resource, params.data)
    return { data: res.data }
  },
  async update(resource: string, params): Promise<ResponseObject> {
    const { id, applications, ...data } = params.data
    const res = await Api.update(resource, id, { applicationIds: applications, ...data })
    return { data: res.data }
  },
  async delete(resource: string, { id }: { id: number }) {
    const res = await Api.delete(resource, id)
    return { data: res.data }
  }
}