import Button from "@mui/material/Button"
import { useState } from "react"
import { useQuery } from "@tanstack/react-query"
import { useDataProvider, useRedirect } from "react-admin"
import Dialog from "@mui/material/Dialog"

export const ModerationField = () => {

  const dataProvider = useDataProvider()

  const resources = [
    { resource: "1:trainingProgram", name: 'Программа тренировок' },
    { resource: "1:nutritionProgram", name: 'Программы питания' },
    { resource: "1:post", name: 'Посты',  filter: {in: [1,2,3,5,6]}},
    { resource: "users", name: 'Тренера', filter: 1 }
  ]

  const queryFunction = async () => {
    return await Promise.all(
      resources.map(async ({resource, name, filter = 2}) => {
        const res = await dataProvider.getList(
          resource,
          {
            filter: {properties: {confirmationStatus: { value: filter}}},
            pagination: { page: 1, perPage: 1},
            sort: { field: "id", order: "ASC"}
          }
        )
        return { resource, name, count: res.total}
      })
    )
  }

  const { data = null, isLoading } = useQuery({
    queryKey: ["moderation-indicate"],
    queryFn: queryFunction
  })

  const [open, setOpen] = useState<boolean>(false)

  if (isLoading) return null

  const handlerDialogClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Button
        variant={"contained"}
        style={{ minWidth: "150px", position: 'relative'}}
        onClick={() => setOpen(!open)}
      >
        <span>модерация</span>
        {data?.some((el: any) => el.count !== 0) && !open && <PingIndicate />}
      </Button>
      <Dialog
        sx={{ minHeight: 700 }}
        maxWidth={'xl'}
        open={open}
        onClose={handlerDialogClose}
      >
        <DialogComponent data={data} setOpen={setOpen} />
      </Dialog>
    </>
  )
}

const PingIndicate = () => {
  return (
    <div style={{
      position: 'absolute',
      top: '5px',
      right: '5px',
      width: '10px',
      height: '10px',
      borderRadius: '50%',
      backgroundColor: '#1565c0'
    }}>
      <div style={{
        backgroundColor: '#1565c0',
        borderRadius: '50%',
        border: '12px solid #222',
        width: '10px',
        height: '10px',
        animation: 'load 0.9s ease-out infinite'
      }}></div>
    </div>
  )
}

const DialogComponent = ({ data, setOpen }) => {


  return (
    <div
      style={{width: '50vw', padding: '30px', display: "grid", gridTemplateColumns: 'auto auto', gap: '15px', justifyContent: 'space-between' }}
    >
      {
        data.map(el => (
          <Item
            key={`${el.name}_${el.resource}`}
            el={el}
            setOpen={setOpen}
          />
        ))
      }
    </div>
  )
}

const Item = ({ setOpen, el }) => {
  const redirect = useRedirect()

  const closeFunc = () => {
    redirect('list', `${el.resource}:moderation`, )
    setOpen(false)
  }

  return (
    <div>
      <Button
        variant="outlined"
        onClick={closeFunc}
        style={{
          display: "flex",
          justifyContent: 'space-between',
          width: '250px'
        }}
      >
        <span>{el.name}</span>
        <span
          style={{width: '20px', height: '20px', borderRadius: '50%', backgroundColor: '#408be1', color: '#fff', fontSize: '12px', justifyContent: 'center'}}
        >
          {el.count}
        </span>
      </Button>
    </div>
  )
}

export const excludeRecourseConfirmationStatusId = [
  'trainingProgram',
  'nutritionProgram',
  'post'
]