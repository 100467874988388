import { NumberInput, TextInput } from "react-admin"
import React from "react"
import { useFormContext } from "react-hook-form"

export const NullInput = ({ el, multiline = false, type = 'text' }) => {
  const { setValue, watch } = useFormContext()

  const source = `properties.${el.symbolCode}.value`

  const value = watch(source)
  const handler = (e: any) => {
    if(!e?.target?.value) {
      console.log('+')
      setValue(source,  '',{shouldDirty: true})
    }
  }

  if (type === 'number') {
    return (
      <NumberInput
        value={value}
        source={source}
        onChange={handler}
      />
    )
  }

  return (
    <TextInput
      value={value}
      source={source}
      onChange={handler}
      multiline={multiline}
      style={{ overflow: "auto", maxHeight: "200px", width: "320px" }}
    />
  )
}