import {
  ArrayField,
  Datagrid, DateField, DateInput,
  Edit, EditButton, FunctionField, ImageField,
  Labeled,
  List,
  Pagination,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput, WithListContext
} from "react-admin"
import React from "react"
import { useAppContext } from "~/modules/app-context"
import { SelectUserInput } from "~/components/inputs/SelectUserInput"
import PersonIcon from '@mui/icons-material/Person'

export const UsersModeration = () => {
  const { applicationId } = useAppContext()

  return (
    <List
      pagination={<PostPagination />}
      filter={{
        applicationId,
        properties: {
          confirmationStatus: { value: 1},
        }
      }}
      emptyWhileLoading
    >
      <Datagrid rowClick={"show"}>
        <TextField label="ID" source="id" />
        <FunctionField label='Аватар' render={
          (record: any) => (
            record.properties.find(prop => prop.symbolCode === "avatar")?.value?.[0]?.url
              ?
              <img
                width={40}
                height={40}
                src={`${record.properties.find(prop => prop.symbolCode === "avatar")?.value?.[0]?.url}`}
                alt="avatar"
              />
              : <PersonIcon />
          )
        } />
        <FunctionField label={"Имя"} render={
          (record: any) => (
            <div>{`${record.properties.find(prop => prop.symbolCode === 'name')?.value}`}</div>
          )
        } />
        <FunctionField label={"Фамилия"} render={
          (record: any) => ( 
            <div>{`${record.properties.find(prop => prop.symbolCode === 'surname')?.value}`}</div>
          )
        } />
        <DateField label="Дата создания" source="createdAt" />
        <DateField label="Дата обновления" source="updatedAt" />
        <EditButton />
      </Datagrid>
    </List>
  )
}

export const UsersModerationEdit = () => {

  return (
    <Edit>
      <SimpleForm>
        <Labeled>
          <TextInput source="id" readOnly />
        </Labeled>
        <Labeled label="Дата создания">
          <DateInput label="" source="createdAt" readOnly />
        </Labeled>
        <Labeled label="Дата обновления">
          <DateInput label="" source="updatedAt" readOnly />
        </Labeled>
        <SelectUserInput />
      </SimpleForm>
    </Edit>
  )
}

export const UsersModerationShow = () => {
  return (
    <Show queryOptions={{ meta: { fullTree: true } }}>
      <SimpleShowLayout>
        <TextField label="ID" source="id" />
        <DateField label="Дата создания" source="createdAt" />
        <DateField label="Дата обновления" source="updatedAt" />
        <ArrayField source="properties">
          <WithListContext
            render={({ data }) => {
              return (
                <div style={{ display: 'flex', flexDirection: 'column'}}>
                  {data.map((item, index) => {
                    if (item.symbolCode === 'reviewsInfo') {
                      return null
                    } else if(item.symbolCode === 'avatar') {
                      return (
                        <Labeled key={`${item.symbolCode}`} label={item?.property?.name}>
                          <ImageField
                            label={''}
                            source={`properties.${index}.value`}
                            src="url"
                          />
                        </Labeled>
                      )
                    } else
                      return (
                        <Labeled key={`${item.symbolCode}`} label={item?.property?.name}>
                          <TextField
                            label={''}
                            source={`properties[${index}].${
                              !item.value &&
                                item.property.type.settings.type === 'file'
                                ? 'file'
                                : 'value'
                            }`}
                            emptyText={'---NULL---'}
                          />
                        </Labeled>
                      )
                  })}
                </div>
              )
            }}
          />
        </ArrayField>
      </SimpleShowLayout>
    </Show>
  )
}

const PostPagination = () => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} />
)