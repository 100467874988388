import {
  List,
  Pagination,
  Datagrid,
  TextField,
  DateField,
  useResourceContext,
  Show,
  SimpleShowLayout,
  Labeled,
  TextInput,
  DateInput,
  Edit,
  SimpleForm,
  BulkDeleteWithConfirmButton,
  EditButton,
  DeleteButton,
  FilterForm,
  NumberInput,
  SelectColumnsButton,
  FilterButton, SelectInput, ArrayField, SingleFieldList, FunctionField
} from "react-admin"
import React, { useEffect, useState } from 'react'
import { getFieldForApp, getFieldForEditAndCreate } from '~/utils/getField'
import { EntityName } from '~/constants'
import { parseResource } from '~/modules/parse-resource'
import { resourceList } from '~/modules/resource-list'
import { ModerationTable } from '~/components/moderationTable/ModerationTable'
import { Stack } from "@mui/material"
import { EntityTemplate } from "~/@types/base-app"
import { ConfirmationStatusColor } from "~/utils/types"

export const EntityForModeration = () => {
  const resource = useResourceContext()

  const [template, setTemplate] = useState<EntityTemplate>()
  const [entityName, setEntityName] = useState<EntityName>()
  const alias = [
    'preview',
    'name',
    'title',
    'description',
    'availabilityLevel',
    'confirmationStatus',
  ]

  useEffect(() => {
    const loadTemplate = async () => {
      const { entityName: name, template } = await parseResource(resource)

      setTemplate(template)
      setEntityName(name)
    }

    loadTemplate()
  }, [resource])

  if (!template || !entityName) {
    return null
  }

  return (
    <List
      pagination={<PostPagination />}
      emptyWhileLoading
    >
      <ListToolbar />
      <Datagrid rowClick={'show'} size={'medium'}>
        {/*<TextField label="ID" source="id" />*/}
        <FunctionField
          label="ID"
          source="id"
          render={(record: any) => (
            <span
              style = {{ backgroundColor: record.properties?.confirmationStatus?.value
                ? ConfirmationStatusColor[record.properties.confirmationStatus.value]
                : ConfirmationStatusColor[0]
              }}
            >
              {record.id}
            </span>
          )}
        />
        <DateField label="Дата создания" source="createdAt" />
        <DateField label="Дата обновления" source="updatedAt" />

        <ArrayField label="User ID" source="$origin.users">
          <SingleFieldList linkType={false}>
            <TextField source="id" />
          </SingleFieldList>
        </ArrayField>

        {template.properties.map(
          el => alias.includes(el.symbolCode) && getFieldForApp(el)
        )}

        <EditButton />
        <DeleteButton />
      </Datagrid>
    </List>
  )
}

const ListToolbar = () => {
  const resource = useResourceContext()

  const [template, setTemplate] = useState<EntityTemplate>()
  const [entityName, setEntityName] = useState<EntityName>()

  useEffect(() => {
    const loadTemplate = async () => {
      const { entityName: name, template } = await parseResource(resource)

      setTemplate(template)
      setEntityName(name)
    }

    loadTemplate()
  }, [resource])

  if (!template || !entityName) {
    return null
  }

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{ ml: 2, mr: 2 }}
    >
      <FilterForm filters={[
        <TextInput label="Поиск" source="query" alwaysOn />,
        <TextInput label="ID" source="id" />,
        <DateInput label="Дата создания" source="createdAt" />,
        <DateInput label="Дата изменения" source="updatedAt" />,
        <NumberInput label={"Пользователи"} source={"userIdsByTypeId.id"} />,
        <TextInput label={"Название"} source={"properties.name.value"} />,
      ]} />
      <div>
        <SelectColumnsButton />
        <FilterButton filters={[
          <TextInput label="Поиск" source="query" alwaysOn />,
          <TextInput label="ID" source="id" />,
          <DateInput label="Дата создания" source="createdAt" />,
          <DateInput label="Дата изменения" source="updatedAt" />,
          <NumberInput label={"Пользователи"} source={"userIdsByTypeId.id"} />,
          <TextInput label={"Название"} source={"properties.name.value"} />,
        ]} />
      </div>
    </Stack>
  )
}

export const EntityForModerationEdit = () => {
  const resource = useResourceContext()

  const [template, setTemplate] = useState<EntityTemplate>()
  const [entityName, setEntityName] = useState<EntityName>()

  useEffect(() => {
    const loadTemplate = async () => {
      const { entityName: name, template } = await parseResource(resource)

      setTemplate(template)
      setEntityName(name)
    }

    loadTemplate()
  }, [resource])

  const label = resourceList.find(el => el.name === resource)

  if (!template || !entityName) {
    return null
  }

  return (
    <Edit>
      <SimpleForm>
        <Labeled label={label?.options.label}>
          <TextInput source="id" readOnly />
        </Labeled>
        <Labeled label="Дата создания">
          <DateInput label="" source="createdAt" readOnly />
        </Labeled>
        <Labeled label="Дата обновления">
          <DateInput label="" source="updatedAt" readOnly />
        </Labeled>
        {template.properties.map(
          el =>
            el.symbolCode === 'confirmationStatus' &&
            getFieldForEditAndCreate(el)
        )}
      </SimpleForm>
    </Edit>
  )
}

export const EntityForModerationShow = () => {
  const resource = useResourceContext()

  const [template, setTemplate] = useState<EntityTemplate>()
  const [entityName, setEntityName] = useState<EntityName>()

  useEffect(() => {
    const loadTemplate = async () => {
      const { entityName: name, template } = await parseResource(resource)

      setTemplate(template)
      setEntityName(name)
    }

    loadTemplate()
  }, [resource])

  if (!template || !entityName) {
    return null
  }

  return (
    <Show queryOptions={{ meta: { fullTree: true } }}>
      <SimpleShowLayout>
        <TextField label="ID" source="id" />
        <DateField label="Дата создания" source="createdAt" />
        <DateField label="Дата обновления" source="updatedAt" />
        {template.properties.map(el => getFieldForApp(el, 'show'))}
        <ModerationTable />
      </SimpleShowLayout>
    </Show>
  )
}

const PostPagination = () => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} />
)
